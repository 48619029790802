<template>
	<div class="container page">
		<MenuCard :bid="bid" class="mini" />

		<div class="board-write">
			<table class="ctable">
				<colgroup>
					<col style="width: 120px;">
					<col>
				</colgroup>
				<tbody>
					<tr>
						<th>제목</th>
						<td class="full"><input type="text" v-model.lazy="title"></td>
					</tr>
					<!--<tr><th colspan=2>글 내용</th></tr>
					<tr><td colspan=2 class="content"><textarea v-model.lazy="form.content"></textarea></td></tr>-->
				</tbody>
			</table>
			<div class="editor-wrapper">
				<ckeditor :editor="editor.editor" v-model="editor.data" :config="editor.config" @ready="editorOnReady"></ckeditor>
			</div>
			<div class="file-wrapper" @drop.prevent="addFiles" @dragover.prevent>
				<div class="file-zone" @click="openFileSelector">
					<button>파일 첨부</button>
					<span>파일 첨부 버튼을 클릭하거나 파일을 끌어 놓아주세요.</span>
					<input type="file" id="file" ref="file" style="display: none;" @change="addFiles">
				</div>
				<div class="files">
					<div class="file" v-for="(file, index) in files" :key="file.vkey">
						<button @click="removeFile(index)">X</button>
						<a :href="file.url" v-if="file.url != ''" target="_blank">{{ file.name }}</a><template v-else>{{ file.name }}</template> <span class="file-size">[{{ file.size }} B]</span>
						<div class="progress" v-if="file.progress != 100">
							<div class="progress-bar bg-success" :class="{'progress-bar-striped progress-bar-animated': file.progress == -1}" role="progressbar" :style="{width: file.progress == -1 ? '100%' : file.progress+'%'}"></div>
						</div>
						<div class="message" v-if="file.message != ''">{{ file.message }}</div>
					</div>
				</div>
			</div>
			<div>
				<button class="btn btn-secondary btn-block" @click="done" :disabled="loading">
					<template v-if="article">수정</template><template v-else>작성 완료</template>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2'
import '@ckeditor/ckeditor5-build-classic/build/translations/ko'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import MenuCard from '../components/MenuCard.vue'

class MyUploadAdapter {
	constructor(loader) {
		this.loader = loader
	}
	
	upload () {
		return this.loader.file.then(file => new Promise((resolve, reject) => {
/*
			// init request
			const xhr = this.xhr = new XMLHttpRequest()
			
			xhr.open('POST', url, true)
			xhr.responseType = 'json'
			
			// init listeners
			response = xhr.response
			resolve({default: response.url})
			
			if (xhr.upload) {
				xhr.upload.addEventListener('progress', (e) => {
					if (e.lengthComputable) {
						loader.uploadTotal = e.total
						loader.uploaded = e.loaded
					}
				})
			}
			
			// send request
			const data = new FormData()
			data.append('upload', this.loader.file)
			this.xhr.send(data)
*/
		}))
	}
	
	abort () {
		if (this.xhr) {
			this.xhr.abort()
		}
	}
}

function MyUploadAdapterPlugin(editor) {
	editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
		return new MyUploadAdapter(loader)
	}
}

export default {
	name: 'BoardWrite',
	components: {
		MenuCard,
		ckeditor: CKEditor.component
	},
	props: {
		article: Object,
		files: {
			type: Array,
			default: () => []
		}
	},
	data () {
		return {
			loading: false,
			title: '',
			fid: '',
			editor: {
				editor: ClassicEditor,
				data: '',
				config: {
					language: 'ko',
					extraPlugins: [
						//MyUploadAdapterPlugin
					]
				}
			}
		}
	},
	computed: {
		bid () { return this.$route.params.bid }
	},
	mounted () {
		if (this.article !== undefined) {
			this.aid = this.article.aid
			this.title = this.article.title
			this.fid = this.article.fid
		}
		for (const file of this.files) {
			file.vkey = file.fidx
			file.progress = 100,
			file.message = ''
			file.url = '/b/board/file/' + file.fidx
		}
	},
	methods: {
		editorOnReady () {
			if (this.article !== undefined) {
				this.editor.data = this.article.content
			}
		},
		done () {
			if (this.title.trim() == '' || this.editor.data.trim() == '') {
				alert('모든 항목을 입력해주세요.')
				return
			}
			
			var form = {
				title: this.title.trim(),
				content: this.editor.data.trim(),
				fid: this.fid
			}
			
			if (this.loading) return
			this.loading = true
			if (!this.article) {
				this.$axios.post(`/board/${this.bid}`, form).then(resp => {
					alert('글 작성 완료')
					setTimeout(() => {
						this.$router.push({name: 'BoardView', params: {bid: this.bid, aid: resp.data.aid}})
					}, 1)
				}).catch((e, xhr) => {
					if (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined) {
						alert(e.response.data.error)
					}else{
						alert('글 저장 중 오류가 발생하였습니다.')
					}
				}).finally(() => {
					this.loading = false
				})
			}else{
				// 글 수정
				this.$axios.patch(`/board/${this.bid}/${this.aid}`, form).then(resp => {
					alert('수정 완료')
					setTimeout(() => {
						this.$router.go(-1)
					}, 1)
				}).catch((e, xhr) => {
					if (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined) {
						alert(e.response.data.error)
					}else{
						alert('글 저장 중 오류가 발생하였습니다.')
					}
				}).finally(() => {
					this.loading = false
				})
			}
		},
		openFileSelector() {
			this.$refs.file.click()
		},
		addFiles(e) {
			var files = event.target.files
			if (files === undefined) {
				files = e.dataTransfer.files
			}
			if(!files) return
			
			// key로 쓸 uuid가 없다면 생성
			if (this.fid == '') {
				this.fid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
					var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
					return v.toString(16)
				})
			}
			
			([...files]).forEach(f => {
				var file = {
					name: f.name, type: f.type, size: f.size,
					url: '',
					vkey: +new Date(),
					fidx: 0,
					progress: -1,
					message: ''
				}
				
				// 파일 이름 체크
				for (const tf of this.files) {
					if (tf.name == f.name) {
						file.progress = 100
						file.message = '같은 이름의 파일은 등록할 수 없습니다.'
						this.files.push(file)
						return
					}
				}
				
				// 파일 용량 체크
				if (f.size > 500 * 1024 * 1024) {
					file.progress = 100
					file.message = '500MiB 이하만 업로드 가능합니다.'
					this.files.push(file)
					return
				}
				
				this.files.push(file)
				
				var form = {name: f.name, type: f.type, size: f.size, fid: this.fid, bid: this.bid}
				if (this.aid !== undefined) {
					form.aid = this.aid
				}
				
				this.$axios.post('/board/file', form).then(resp => {
					file.fidx = resp.data.fidx
					file.progress = 0
					
					this.$axios.put(resp.data.presigned, f, {
						headers: {'Content-Type': f.type},
						onUploadProgress: (e) => {
							if (e.lengthComputable) {
								var max = e.total
								var current = e.loaded
								var percent = current * 100 / max
								file.progress = percent
							}else{
								file.progress = -1
							}
						}
					}).then(resp => {
						file.url = '/board/file/' + file.fidx
					}).catch((e, xhr) => {
						file.message = '파일 업로드 중 오류가 발생하였습니다. (2)'
					}).finally(() => {
						file.progress = 100
					})
				}).catch((e, xhr) => {
					file.progress = 100
					if (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined) {
						file.message = e.response.data.error
					}else{
						file.message = '파일 업로드 중 오류가 발생하였습니다. (1)'
					}
				})
			})
			
			this.$refs.file.value = ''
		},
		removeFile(index) {
			const file = this.files[index]
			
			if (file.fidx != 0) {
				this.$axios.delete(`/board/file/${file.fidx}`).then(resp => {
					this.files.splice(index, 1)
				}).catch((e, xhr) => {
					if (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined) {
						alert(e.response.data.error)
					}else{
						alert('파일 삭제 중 오류가 발생하였습니다.')
					}
				})
			}else{
				this.files.splice(index, 1)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.ctable {
	margin-bottom: 10px;
	
	td {
		input[type=text], textarea {
			font-size: 16px;
		}

		&.full {
			padding: 0;
			input {
				width: 100%;
				height: 35px;
				border: none;
				padding-left: 8px;
			}
		}
		&.content {
			padding: 0;

			textarea {
				width: 100%;
				height: 300px;
				border: none;
				resize: none;
				padding: 8px;
			}
			textarea:focus {
				outline: none;
			}
		}
	}
}

.file-wrapper {
	margin-bottom: 20px;
	
	.file-zone {
		border: 1px solid #ddd;
		background-color: #f6f6f6;
		padding: 8px;
		cursor: pointer;
		font-size: 14px;
		
		button {
			border: 1px solid #ddd;
			background-color: #fff;
		}
		span {
			margin-left: 12px;
			line-height: 38px;
		}
	}
	.files {
		border: 1px solid #ddd;
		border-top: none;
		padding: 8px;
		font-size: 14px;
		
		.file {
			border-bottom: 1px solid #ddd;
			padding: 0px 4px 8px;
			margin-bottom: 8px;
			
			button {
				border: none;
				background-color: #dc3545;
				color: #fff;
				border-radius: 4px;
				font-size: 12px;
				margin-right: 6px;
			}
			a {
				color: #333;
			}
			
			& > div {
				margin-top: 8px;
				margin-left: 32px;
			}
			
			.message {
				color: #dc3545;
			}
			
			.file-size {
				font-size: 0.8em;
				color: #666;
			}
		}
	}
}
</style>

<style lang="scss">
.editor-wrapper {
	margin-bottom: 20px;
	
	.ck-editor__editable_inline {
		min-height: 300px;
	}
}
</style>
